@import 'normalize';

  @font-face {
    font-family: 'FontAwesome';
    font-display: swap;
    src: url('//use.fontawesome.com/releases/v4.7.0/css/font-awesome-css.min.css');
    src: url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
      url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.woff2') format('woff2'),
      url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.woff') format('woff'),
      url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.ttf') format('truetype'),
      url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.svg#fontawesomeregular') format('svg');
  //  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
    font-weight: normal;
    font-style: normal;
  }
/*
====================================
Default Styling
====================================
*/

img {
	max-width: 100%;
}

body {
	color: #000;
}
header{
	padding:1% 0 0 0;
}
a.link-4 {
  color:#1f253d;
  content: '';
  border-bottom: solid 1px black;

}

a:hover.link-4 {
  border-bottom: solid thin #5CAAEF;
}

a.link-4  {
  -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
}


//footer
footer{
  background: #757575;
  padding:2%;
}
ul.cities li{
  list-style:none;
}
ul.cities a{
  color:#fff;
  font-size:.8em;
}
ul.cities a:hover{
  color:rgba(85, 32, 32, 0.7);
}
footer h3{

  text-transform: uppercase;
  text-align:center;
  color:#fff;
}
footer p{
  text-align:center;
  color:#fff; 
}
.socialsNetwork{
  font-size:.9em;
  width:100%;
  padding:1%;
background: #2e2e2e;
}

		  /*BROWSER*/
.browser {
  background-color: #F1F1F1;
  max-width: 100%;
  height: auto;
  border-radius: 3px;
  overflow: hidden;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.top-bar--buttons {
  height: 20px;
  background-color: #CCCCCC;
  padding-left: 5px;
}
.top-bar--buttons i {
  width: 8px;
  height: 8px;
  display: block;
  margin: 5px 0 0 5px;
  border-radius: 100%;
  float: left;
}
.top-bar--buttons .red {
  background-color: #fc635e;
  border: 1px solid #dc4441;
}
.top-bar--buttons .yellow {
  background-color: #fdbc40;
  border: 1px solid #db9c31;
}
.top-bar--buttons .green {
  background-color: #35cd4b;
  border: 1px solid #24a732;
}
.top-bar--tabs {
  position: relative;
}
.top-bar--tabs .tab {
  position: absolute;
  height: 15px;
  background: #E6E6E6;
  width: 150px;
  top: -15px;
  left: 80px;
}
.top-bar--tabs .tab::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 16px;
  position: absolute;
  border-color: transparent #E6E6E6 transparent transparent;
  border-right-style: solid;
  border-left-width: 0;
  left: -16px;
}
.top-bar--tabs .tab::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 16px;
  position: absolute;
  border-color: transparent transparent transparent #E6E6E6;
  border-left-style: solid;
  border-right-width: 0;
  right: -16px;
}
.top-bar--url {
  background-color: #E6E6E6;
  height: 20px;
}

.screenshot img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.browser:hover {
  bottom: 0px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.2);
}


.buttonUber{
  background: #01579B;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  height: 48px;
  letter-spacing: .5px;
  line-height: 48px;
  padding: 0 24px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  
}
.light-gray {
  background-color: #f6f6f6;
}
.dot{border: 1px dotted #212121!important;}

.link-more{
  text-transform:uppercase;
  font-weight:900;
  padding-top:25px;
}

.link-3 a {
  color: #00c853;
  text-decoration: none;
}

.link-3 a:hover {
  text-decoration: underline;
}
 
/*FAQ*/
.faq-header {
  font-size: 2em;
  border-bottom: 1px dotted #ccc;
  padding: 1em 0;
}
.faq-c {
  border-bottom: 1px dotted #ccc;
  padding: 1em 0;
}
.faq-t {
  line-height: 1em;
  color: #aaa;
  float: left;
  font-weight: 700;
  padding-right: 0.3em;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}
.faq-o {
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
  -ms-transform: rotate(-45deg);
  -ms-transform-origin: 50% 50%;
  -webkit-transform: rotate(-45deg);
  -webkit-transform-origin: 50% 50%;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}
.faq-q {
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 100;
}
.faq-a {
  clear: both;
  color: #666;
  display: none;
  padding-left: 1.5em;
}
.fa-quote-left {
  color: #444;
  background: #eee;
  border: 2px solid #ccc;
  padding: 10px 12px;
  border-radius: 100%;
}
.activity-pill {
  position:relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
min-height: 140px;
margin-top: 10px;
margin-bottom: 10px;
padding: 25px 30px;
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
border-radius: 25px;
background-color: #0c1524;
-webkit-transform-origin: 50% 0%;
-ms-transform-origin: 50% 0%;
transform-origin: 50% 0%;
color: #5b769b;
}

.activity-pill.centered {
margin-top: 0px;
margin-bottom: 0px;
padding: 10px 15px;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
background-image: url('../images/quote.svg');
background-position: 96% 22px;
background-size: 50px;
background-repeat: no-repeat;
-webkit-transform-origin: 50% 0%;
-ms-transform-origin: 50% 0%;
transform-origin: 50% 0%;
}
.activity-pill p{
color: #fff;
}
.activity-sub-heading {
margin-bottom: 5px;
color: #fff;
font-size: 18px;
font-weight: 700;
}

.pill-description {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 10px 30px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: start;
-webkit-align-items: flex-start;
-ms-flex-align: start;
align-items: flex-start;
text-align: left;
}

.pill-description.small {
padding-right: 20px;
padding-left: 20px;
font-size: 13px;
}
.new-pill {

position: absolute;
top: 8px;
right: 8px;
z-index: 2;
padding-right: 5px;
padding-left: 5px;
border-radius: 12px;
background-color: #b00020;
color: #fff;
font-size: 10px;
line-height: 15px;
}

.new-pill.upper-right {
left:5px;
top: 5px;
right: auto;
bottom: auto;
}
.space-button-centered {
position: relative;
 margin-top: 25px;
padding:5%;
background-color: rgba(28, 46, 70, .65);
box-shadow: inset 0 0 0 1px rgba(91, 118, 155, .34);
opacity: 1;
-webkit-transition: opacity 200ms ease, box-shadow 200ms ease;
transition: opacity 200ms ease, box-shadow 200ms ease;
color: #fff;
font-size:.5em;
font-weight: 700;
letter-spacing: 1px;
text-decoration: none;
text-transform: uppercase;
}

.space-button-centered:hover {
border-color: #5b769b;
box-shadow: inset 0 0 0 1px #5b769b;
opacity: 1;
}
.headerTopUber{
  width:97% ; 
  margin:0 auto;
  background-color:#f6f6f6;
  columns: #2b2a2a;
}
/* Position child elements relative to this element */
.aspect-ratio-box {
  position: relative;
}

/* Create a pseudo element that uses padding-bottom to take up space */
.aspect-ratio-box::after {
  display: block;
  content: '';
  /* 16:9 aspect ratio */
  padding-bottom: 56.25%;
}

/* Image is positioned absolutely relative to the parent element */
.aspect-ratio-box img {
  /* Image should match parent box size */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
/*new nav*/

.toggle, [id^=drop] {
  display: none;
 }
 
 nav {
   margin: 0;
   padding: 0;
   background-color: #444;
   z-index: 999;
 }
 
 #logo {
   display: block;
   padding: 0 30px;
   float: left;
   font-size: 20px;
   line-height: 60px;
 }
 
 nav:after {
   content: "";
   display: table;
   clear: both;
 }
   
   
     nav ul {
       padding: 0;
       margin: 0;
       list-style: none;
       position: relative;
     }
     
     nav ul li {
       margin: 0px;
       display: inline-block;
       float: left;
       background-color: #444;
     }
     
     nav a {
       display: block;
       padding: 0 1em;
       color: #FFF;
       font-size: .8em;
       font-weight:300;
       line-height: 60px;
       text-decoration: none;
     }
     
     nav ul li ul li:hover { background: #000000; }
     
     nav a:hover { background-color: #000000; }
     
     nav ul ul {
       display: none;
       position: absolute;
       top: 60px;
       z-index:999;
     }
     
     nav ul li:hover > ul { display: inherit; }
     
     nav ul ul li {
       width: 250px;
       float: none;
       display: list-item;
       position: relative;
     }
     
     nav ul ul ul li {
       position: relative;
       top: -60px;
       left: 170px;
     }
     
    nav ul li > a:after { content: ' +'; }
     
     li > a:only-child:after { content: ''; }
     
     
     /* Media Queries
     --------------------------------------------- */
     
     @media all and (max-width : 1200px) {
     
     
     nav { margin: 0; }
     
     .toggle + a,
      .menu { display: none; }
     
     .toggle {
       display: block;
       background-color: #444;
       padding: 0 20px;
       color: #FFF;
       font-size: 20px;
       line-height: 60px;
       text-decoration: none;
       border: none;
     }
     
     .toggle:hover { background-color: #000000; }
     
     [id^=drop]:checked + ul { display: block; }
     
     nav ul li {
       display: block;
       width: 100%;
     }
     
     nav ul ul .toggle,
      nav ul ul a { padding: 0 40px; }
     
     nav ul ul ul a { padding: 0 80px; }
     
     nav a:hover,
      nav ul ul ul a { background-color: #000000; }
     
     nav ul li ul li .toggle,
      nav ul ul a { background-color: #212121; }
     
     nav ul ul {
       float: none;
       position: static;
       color: #ffffff;
     }
     
     nav ul ul li:hover > ul,
     nav ul li:hover > ul { display: none; }
     
     nav ul ul li {
       display: block;
       width: 100%;
     }
     
     nav ul ul ul li { position: static;
     
     }
     }
     
     @media all and (max-width : 330px) {
     
     nav ul li {
       display: block;
       width: 94%;
     }
     
     }
     .tiny-orange-call-out {
       padding: 0;
       color: #cf6163;
       font-size: .9em;
       font-weight: 500;
     }
 
     .destination__sub-heading {
       margin-top: 10px;
       color: #000;
       font-size: 1.5em;
       line-height: 1.5em;
       font-weight: 300;
       color: #999;
     }
     /*Quotes Doordash*/
     .quote-text{
      font-style: italic;
    }
    .author-text{
      color:#3A3C51;
      text-align:right;
    }
    .fa-arrow-circle-right:hover{
      opacity: .8;
      color: #F44336;
    }
    #profile-description {
      max-width: 100%;
      margin-top: 50px;
      position: relative;
    }
    
    #profile-description .text {
      /*   width: 660px;  */
      margin-bottom: 5px;
      color: #000;
      padding: 0 15px;
      position: relative;
      font-size: 14px;
      display: block;
    }
    
    #profile-description .show-more {
      /*   width: 690px;  */
      color: #111;
      position: relative;
      font-size: 12px;
      padding-top: 5px;
      height: 20px;
      text-align: center;
      background: #f1f1f1;
      cursor: pointer;
    }
    
    #profile-description .show-more:hover {
      color: #1779dd;
    }
    
    #profile-description .show-more-height {
      height: 65px;
      overflow: hidden;
    }
.secondary-nav a {
  line-height:2em;
  display: block;
  width: 100%;
  border-bottom: 1px dotted;
  text-decoration: none;
  margin: .5em 0;
}
.cities-col{
  -webkit-column-count: 6;
        column-count: 6;
        -webkit-column-gap: 8em;
        column-gap: 8em;
        -webkit-column-rule: 1px dashed #cccccc;
        column-rule: 1px dashed #cccccc;
        color: #fff;
        transition: all 200ms;
    }
    ul.cities-col {
      list-style: none;

    }
   ul.cities-col > li > a{
      color:#fff;
      line-height: 2em;
      font-weight:300;
      font-size:1em;
    }
    .cities-col:hover {
        color: #1a1a1a;
        transition: all 200ms;
    }
    @media (max-width: 1200px) {
        .cities-col {
            -webkit-column-count: 4;
            column-count: 4;
        }
    }
    @media (max-width: 800px) {
        .cities-col{
            -webkit-column-count: 1;
            column-count: 1;
        }
        ul.cities-col > li > a{
          font-size:1.5em;
    }
  }